<template>
  <div class="row">
    <div class="col-12 col-lg-4 c g" v-if="!selected">
        <div class="card card-body">
            <p>
                اختر الفترة المراد استخراج لها تقرير الغياب والمخالفات الخ...
            </p>
            <div class="form-group">
              <label for="">الفترة من</label>
              <input type="date"
                class="form-control" v-model="from" id="from">
            </div>
            <div class="form-group">
              <label for="">الفترة الى</label>
              <input type="date"
                class="form-control" v-model="to" id="to">
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_items" value="ghiab">
                سجل الغياب
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_items" value="late">
                سجل التأخر
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_items" value="attend">
                سجل الحضور
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_items" value="ex">
                سجل الاستئذان
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_items" value="bis">
                المخالفات السلوكية
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_items" value="breaks">
                المواقف اليومية الطارئة
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_items" value="visits">
                زيارات ولي الأمر
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_items" value="archive">
                الرسائل المرسلة لولي الأمر
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected_items" value="hours">
                ساعات التطوع
              </label>
            </div>
            <br>
            <div class="col-12 text-center g">
                <button class="btn btn-success" @click="selected = true; getReport()">
                    عرض التقرير الآن
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-4 c g" v-if="loading && selected">
        <div class="card card-body text-center">
            <img :src="require('@/assets/images/loading.svg')" style="width: 100px; margin: 0px auto">
            <br>
            جاري الاستعلام...
        </div>
    </div>
    <div class="col-12" v-if="!loading && selected">
        <div class="row">
            <div class="col-12 col-lg-4 c g" v-if="report.student">
                <div class="card">
                    <div class="card-body">
                        <div class="media">
                            <a class="d-flex" href="javascript:;">
                                <img :src="require('@/assets/images/arab_child.png')" style="width: 64px">
                            </a>
                            <div class="media-body align-self-center">
                                <h5>{{ report.student.name }}</h5>
                                {{ report.student.number }}
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="col-12 table-responsive" style="padding: 0px" id="f0">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr class="dnone">
                                        <td>
                                            الاسم
                                        </td>
                                        <td>
                                            {{ report.student.name }}
                                        </td>
                                    </tr>
                                    <tr class="dnone">
                                        <td>
                                            رقم الهوية
                                        </td>
                                        <td>
                                            {{ report.student.number }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            الصف
                                        </td>
                                        <td>
                                            {{ report.student.classname }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            الفصل
                                        </td>
                                        <td>
                                            {{ report.student.classroom }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            الهاتف
                                        </td>
                                        <td>
                                            {{ report.student.phones.join(',') }}
                                        </td>
                                    </tr>
                                    <tr v-if="selected_items.includes('ghiab')">
                                        <td>
                                             ايام الغياب بدون عذر
                                        </td>
                                        <td>
                                            {{ ghiabs.reports[3].length }}
                                        </td>
                                    </tr>
                                    <tr v-if="selected_items.includes('ghiab')">
                                        <td>
                                             ايام الغياب بعذر
                                        </td>
                                        <td>
                                            {{ ghiabs.reports[5].length }}
                                        </td>
                                    </tr>
                                    <tr v-if="selected_items.includes('late')">
                                        <td>
                                             ايام التأخر
                                        </td>
                                        <td>
                                            {{ ghiabs.reports[2].length }}
                                        </td>
                                    </tr>
                                    <tr v-if="selected_items.includes('attend')">
                                        <td>
                                             ايام الحضور
                                        </td>
                                        <td>
                                            {{ ghiabs.reports[1].length }}
                                        </td>
                                    </tr>
                                    <tr v-if="selected_items.includes('ex')">
                                        <td>
                                             مرات الاستئذان
                                        </td>
                                        <td>
                                            {{ report.exs.length }}
                                        </td>
                                    </tr>
                                    <tr v-if="selected_items.includes('bis')">
                                        <td>
                                             المخالفات السلوكية
                                        </td>
                                        <td>
                                            {{ report.bis.length }}
                                        </td>
                                    </tr>
                                    <tr v-if="selected_items.includes('breaks')">
                                        <td>
                                             المواقف الطارئة
                                        </td>
                                        <td>
                                            {{ report.breaks.length }}
                                        </td>
                                    </tr>
                                    <tr v-if="selected_items.includes('visits')">
                                        <td>
                                             زيارات ولي الأمر
                                        </td>
                                        <td>
                                            {{ report.visits.length }}
                                        </td>
                                    </tr>
                                    <tr v-if="selected_items.includes('archive')">
                                        <td>
                                             ساعات التطوع
                                        </td>
                                        <td>
                                            {{ report.hours.length }}
                                        </td>
                                    </tr>
                                    <tr v-if="selected_items.includes('archive')">
                                        <td>
                                             الرسائل المرسلة
                                        </td>
                                        <td>
                                            {{ report.messages.length }}
                                        </td>
                                    </tr>
                                    <tr class="dnone">
                                        <td>
                                            تاريخ الطباعة
                                        </td>
                                        <td>
                                            {{ now }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12 text-center">
                            <button class="btn btn-primary" @click="printall()">
                                <i class="fa fa-print"></i>
                                طباعة التقرير
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-8 c g">
                <div class="card" v-if="selected_items.includes('ghiab')" id='f1'>
                    <div class="card-header">
                        سجل الغياب بدون عذر <button class="btn btn-sm btn-warning" @click="printdiv('f1')"><i class="fa fa-print"></i> طباعة</button>
                    </div>
                    <div class="card-body">
                        <div class="col-12 table-responsive" style="min-height: auto !important">
                            <table class="table table-hover table-bordered table-sm">
                                <thead>
                                    <th>
                                        التاريخ
                                    </th>
                                    <th>
                                        الحالة
                                    </th>
                                    <th>
                                        التوقيت
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="r in ghiabs.reports[3]" :key="r.time + r._id">
                                        <td>
                                            {{ r.date }}
                                        </td>
                                        <td>
                                            غائب بدون عذر
                                        </td>
                                        <td>
                                            {{ r.time }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="selected_items.includes('ghiab')" id="f2">
                    <div class="card-header">
                        سجل الغياب بعذر <button class="btn btn-sm btn-warning" @click="printdiv('f2')"><i class="fa fa-print"></i> طباعة</button>
                    </div>
                    <div class="card-body">
                        <div class="col-12 table-responsive" style="min-height: auto !important">
                            <table class="table table-hover table-bordered table-sm">
                                <thead>
                                    <th>
                                        التاريخ
                                    </th>
                                    <th>
                                        الحالة
                                    </th>
                                    <th>
                                        التوقيت
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="r in ghiabs.reports[5]" :key="r.time + r._id">
                                        <td>
                                            {{ r.date }}
                                        </td>
                                        <td>
                                            غائب بعذر
                                        </td>
                                        <td>
                                            {{ r.time }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="selected_items.includes('late')" id="f3">
                    <div class="card-header">
                        سجل التأخر <button class="btn btn-sm btn-warning" @click="printdiv('f3')"><i class="fa fa-print"></i> طباعة</button>
                    </div>
                    <div class="card-body">
                        <div class="col-12 table-responsive" style="min-height: auto !important">
                            <table class="table table-hover table-bordered table-sm">
                                <thead>
                                    <th>
                                        التاريخ
                                    </th>
                                    <th>
                                        الحالة
                                    </th>
                                    <th>
                                        التوقيت
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="r in ghiabs.reports[2]" :key="r.time + r._id">
                                        <td>
                                            {{ r.date }}
                                        </td>
                                        <td>
                                            تأخر
                                        </td>
                                        <td>
                                            {{ r.time }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="selected_items.includes('attend')" id="f4">
                    <div class="card-header">
                        سجل الحضور <button class="btn btn-sm btn-warning" @click="printdiv('f4')"><i class="fa fa-print"></i> طباعة</button>
                    </div>
                    <div class="card-body">
                        <div class="col-12 table-responsive" style="min-height: auto !important">
                            <table class="table table-hover table-bordered table-sm">
                                <thead>
                                    <th>
                                        التاريخ
                                    </th>
                                    <th>
                                        الحالة
                                    </th>
                                    <th>
                                        التوقيت
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="r in ghiabs.reports[1]" :key="r.time + r._id">
                                        <td>
                                            {{ r.date }}
                                        </td>
                                        <td>
                                            حاضر
                                        </td>
                                        <td>
                                            {{ r.time }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="selected_items.includes('ex')" id="f5">
                    <div class="card-header">
                        سجل الاستئذان <button class="btn btn-sm btn-warning" @click="printdiv('f5')"><i class="fa fa-print"></i> طباعة</button>
                    </div>
                    <div class="card-body">
                        <div class="col-12 table-responsive" style="min-height: auto !important">
                            <table class="table table-hover table-bordered table-sm">
                                <thead>
                                    <th>
                                        التاريخ
                                    </th>
                                    <th>
                                        الحالة
                                    </th>
                                    <th>
                                        التوقيت
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="r in report.exs" :key="r.time + r._id">
                                        <td>
                                            {{ r.date }}
                                        </td>
                                        <td>
                                            استئذان
                                        </td>
                                        <td>
                                            {{ r.time }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="selected_items.includes('bis')" id="f6">
                    <div class="card-header">
                        سجل المخالفات السلوكية <button class="btn btn-sm btn-warning" @click="printdiv('f6')"><i class="fa fa-print"></i> طباعة</button>
                    </div>
                    <div class="card-body">
                        <div class="col-12 table-responsive" style="min-height: auto !important">
                            <table class="table table-hover table-bordered table-sm">
                                <thead>
                                    <th>
                                        التاريخ
                                    </th>
                                    <th>
                                        المخالفة
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="r in report.bis" :key="r.time + r._id">
                                        <td>
                                            {{ r.date }}
                                        </td>
                                        <td>
                                            {{ r.bi }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="selected_items.includes('breaks')" id="f7">
                    <div class="card-header">
                        المواقف اليومية الطارئة <button class="btn btn-sm btn-warning" @click="printdiv('f7')"><i class="fa fa-print"></i> طباعة</button>
                    </div>
                    <div class="card-body">
                        <div class="col-12 table-responsive" style="min-height: auto !important">
                            <table class="table table-hover table-bordered table-sm">
                                <thead>
                                    <th>
                                        التاريخ
                                    </th>
                                    <th>
                                        الموقف	
                                    </th>
                                    <th>
                                        الاجراء	
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="r in report.breaks" :key="r.time + r._id">
                                        <td>
                                            {{ r.date }}
                                        </td>
                                        <td>
                                            {{ r.content }}
                                        </td>
                                        <td>
                                            {{ r.action }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="selected_items.includes('visits')" id="f8">
                    <div class="card-header">
                        زيارات ولي الامر <button class="btn btn-sm btn-warning" @click="printdiv('f8')"><i class="fa fa-print"></i> طباعة</button>
                    </div>
                    <div class="card-body">
                        <div class="col-12 table-responsive" style="min-height: auto !important">
                            <table class="table table-hover table-bordered table-sm">
                                <thead>
                                    <th>
                                        التاريخ
                                    </th>
                                    <th>
                                        الموضوع
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="r in report.visits" :key="r.time + r._id">
                                        <td>
                                            {{ r.date }}
                                        </td>
                                        <td>
                                            {{ r.content }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="selected_items.includes('hours')" id="f9">
                    <div class="card-header">
                        ساعات التطوع <button class="btn btn-sm btn-warning" @click="printdiv('f9')"><i class="fa fa-print"></i> طباعة</button>
                    </div>
                    <div class="card-body">
                        <div class="col-12 table-responsive" style="min-height: auto !important">
                            <table class="table table-hover table-bordered table-sm">
                                <thead>
                                    <th>
                                        المجال العام للتطوع	
                                    </th>
                                    <th>
                                        تفاصيل التطوع	
                                    </th>
                                    <th>
                                        عدد الساعات	
                                    </th>
                                    <th>
                                        التاريخ
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="r in report.hours" :key="r.time + r._id">
                                        <td>
                                            {{ r.type }}
                                        </td>
                                        <td>
                                            {{ r.details }}
                                        </td>
                                        <td>
                                            {{ r.hours }}
                                        </td>
                                        <td>
                                            {{ r.date }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="selected_items.includes('archive')" id="f10">
                    <div class="card-header">
                        الرسائل المرسلة لولي الامر <button class="btn btn-sm btn-warning" @click="printdiv('f10')"><i class="fa fa-print"></i> طباعة</button>
                    </div>
                    <div class="card-body">
                        <div class="col-12 table-responsive" style="min-height: auto !important">
                            <table class="table table-hover table-bordered table-sm">
                                <thead>
                                    <th>
                                        التاريخ
                                    </th>
                                    <th>
                                        الجوال
                                    </th>
                                    <th>
                                        الحالة
                                    </th>
                                    <th>
                                       التاريخ
                                    </th>
                                </thead>
                                <tbody>
                                    <tr v-for="r in report.messages" :key="r.time + r._id">
                                        <td>
                                            {{ r.date }}
                                        </td>
                                        <td class="noexchangedate">
                                            {{ r.message }}
                                        </td>
                                        <td>
                                            <span v-if="r.status == 0" class='btn btn-sm btn-warning'>
                                                <i class="fa fa-clock-o"></i>
                                                جاري الارسال
                                            </span>
                                            <span v-if="r.status == 1" class='btn btn-sm btn-success'>
                                                <i class="fa fa-check"></i>
                                                تم الارسال
                                            </span>
                                            <span v-if="r.status == 2" class='btn btn-sm btn-danger'>
                                                <i class="fa fa-times"></i>
                                                فشل الارسال
                                            </span>
                                            <span v-if="r.status == 3" class='btn btn-sm btn-info'>
                                                <i class="fa fa-whatsapp"></i>
                                                مرسل بواسطة اداة الواتساب
                                            </span>
                                        </td>
                                        <td>
                                            {{ r.date }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            selected: false,
            selected_items: [
                "ghiab",
                "late",
                "attend",
                "ex",
                "bis",
                "breaks",
                "visits",
                "archive",
                "hours"
            ],
            from: new Date().toISOString().split("T")[0],
            to: new Date().toISOString().split("T")[0],
            loading: true,
            user: JSON.parse(localStorage.getItem('user')),
            report: {},
            ghiabs: {},
            now: new Date().toISOString().replace('T', ' ').split(".")[0],
        }
    },
    created(){
        var date = new Date();
        date.setDate(date.getDate() - 90);
        this.from = "2024-08-18"//date.toISOString().split("T")[0]
    },
    methods: {
        getReport(){
            var g = this;
            $.post(api + '/user/students/reports/full-report', {
                jwt: this.user.jwt,
                number: window.location.href.split("full-report/")[1],
                from: $("#from").val(),
                to: $("#to").val(),
                items: this.selected_items
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.report = r.response
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
            $.post(api + '/user/students/reports/between', {
                jwt: this.user.jwt,
                from: g.from,
                to: g.to,
                number: window.location.href.split("full-report/")[1],
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.ghiabs = r.response[0]
                }
            }).fail(function(){
                g.loading = false
            })
        },
        printdiv(id){
            var mywindow = window.open('', 'TAHDIR.NET');
            mywindow.document.write('<html dir=rtl><head><title>' + document.title  + '</title>');
            mywindow.document.write(`
            <style>
            button{
                display:none;
            }
            table, td, th {
            border: 1px solid;
            }

            table {
            width: 100%;
            border-collapse: collapse;
            }
            </style>
            `);
            mywindow.document.write('</head><body >');
            mywindow.document.write(`<h5>${this.report.student.name}</h5>`);
            mywindow.document.write(document.getElementById(id).innerHTML);
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();

            return true;
        },
        printall(){
            var g = this;
            var logo = require('@/assets/images/school.png');
            if(g.user?.settings?.logo){
                logo = g.user?.settings?.logo
            }
            var mywindow = window.open('', 'TAHDIR.NET');
            mywindow.document.write('<html dir=rtl><head><title>' + document.title  + '</title>');
            mywindow.document.write(`
            <style>
            button{
                display:none;
            }
            table, td, th {
            border: 1px solid;
            }

            table {
            width: 100%;
            border-collapse: collapse;
            }
            </style>
            `);
            mywindow.document.write(`</head><body id=ff><center>
            <h4>
                <img src='${logo}' style='width: 60px; height: 60px;'> 
                <br>
                ${g.user.name}
            </h4>
            <h2>تقرير شامل للطالب</h2></center>`);
            for(var i = 0; i < 11; i++){
                if(document.getElementById('f'+i)?.innerHTML){
                    if(i > 0){
                        mywindow.document.write('<br>');
                    }
                    mywindow.document.write(document.getElementById('f'+i).innerHTML);
                }
            }
            mywindow.document.write('</body></html>');
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();

            return true;
        }
    }
}
</script>

<style>

</style>